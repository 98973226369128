import { createSlice, createSelector } from "@reduxjs/toolkit";
import { coreApi } from "api/core";

const baseUrl = "/suppliers";
const initialState = {
  suppliers: [],
  timestamp: null,
  loading: false,
};

const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setSuppliers: (state, action) => {
      state.suppliers = action.payload;
      state.timestamp = +new Date();
    },
    setSuppliersLoading: (state) => {
      state.loading = true;
    },
    setSuppliersReady: (state) => {
      state.loading = false;
    },
  },
});

export const { setSuppliers, setSuppliersLoading, setSuppliersReady } =
  suppliersSlice.actions;

export default suppliersSlice.reducer;

export const getSuppliers = () => async (dispatch) => {
  dispatch(setSuppliersLoading());
  try {
    const data = await coreApi.fetch(`${baseUrl}/get-suppliers/`);

    dispatch(setSuppliers(data));

    return data;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setSuppliersReady());
  }
};

const suppliersSelector = ({ suppliers }) => suppliers?.suppliers || [];
const suppliersStatusSelector = ({ suppliers }) => suppliers.loading;

export const selectSuppliers = createSelector(suppliersSelector, (p) => p);
export const selectSuppliersStatus = createSelector(
  suppliersStatusSelector,
  (loading) => loading
);
