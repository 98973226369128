import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import MapIcon from "@material-ui/icons/Map";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import clsx from "clsx";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { PAST_DELIVERIES_TABLE_COLUMNS } from "constants/ui-constants";
import { getColumns, getLocalization } from "util/table-utils";
import { mapTableData } from "util/helpers";
import { PATHS } from "util/appConstants";
import {
  CustomMultiSelect,
  DatePicker,
} from "components/Shared/mui-formik-inputs";
import {
  getpastDeliveries,
  pastDeliveriesDocument,
  selectpastDeliveries,
  selectpastDeliveriesStatus,
} from "redux/slices/pastDeliveriesSlice";
import "moment/locale/en-gb";
import "moment/locale/de";
import Navbar from "components/Navbar";
import DarkLayout from "components/Shared/DarkLayout";
import { selectUser } from "redux/slices/userSlice";
import { getTours, selectTours } from "redux/slices/tourSlice";

const locales = {
  "en-us": "en",
  en: "en",
  de: "de",
};

const useStyles = makeStyles({
  orderRow: {
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#1F1F1F",
    },
  },
});

const PastDeliveries = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    from: moment().subtract(1, "month").startOf("month").toDate(),
    to: moment().subtract(1, "day").endOf("day").toDate(),
    tours: [],
    delivery_timings: [],
  });
  const classes = useStyles();
  const loading = useSelector(selectpastDeliveriesStatus);
  const pastdeliveries = useSelector(selectpastDeliveries);
  let appliedFilter;
  const user = useSelector(selectUser);
  const tours = useSelector(selectTours);
  useEffect(() => {
    if (!tours.length && !loading) {
      dispatch(getTours());
    }
  }, [dispatch, tours]);

  const mapNamesToIds = (names) => {
    const nameToIdMap = new Map(tours.map((tour) => [tour.name, tour.id]));
    return names
      .map((name) => nameToIdMap.get(name))
      .filter((id) => id !== undefined);
  };

  const mapNamesToDeliveryTimings = (selectedTourNames) => {
    const nameToTimingsMap = new Map(
      tours.map((tour) => [tour.name, tour.delivery_timings])
    );
    const allTimings = selectedTourNames
      .map((name) => nameToTimingsMap.get(name) || [])
      .flat();
    return Array.from(new Set(allTimings));
  };

  useEffect(() => {
    if (tours.length) {
      const newTimings = mapNamesToDeliveryTimings(
        tours.map((tour) => tour.name)
      );
      setForm((prevForm) => {
        const updatedForm = {
          ...prevForm,
          delivery_timings: newTimings,
          tours: tours.map((tour) => tour.name),
        };
        console.log("updated form: ", updatedForm);

        return updatedForm;
      });
    }
  }, [tours]);

  const fetch = async (params = form) => {
    // TODO: Initially tourIds and delivery_timings are empty, handle this
    setForm(params);
    const tourIds = mapNamesToIds(params.tours);
    await dispatch(
      getpastDeliveries({
        from: moment(params.from).format("YYYY-MM-DD"),
        to: moment(params.to).format("YYYY-MM-DD"),
        tourIds: tourIds,
        deliveryTimings: params.delivery_timings,
      })
    );
  };

  const formatKey = (key) => {
    return key
      .split(".")
      .map((part, index) => {
        if (index > 0) {
          return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        }
        return part.toLowerCase();
      })
      .join("");
  };

  const getFilteredData = (filterState) => {
    appliedFilter = filterState.reduce((acc, filter) => {
      const formattedKey = formatKey(filter.column.field);
      acc[formattedKey] = filter.value;
      return acc;
    }, {});
  };

  const downloadPDF = async (params) => {
    dispatch(
      pastDeliveriesDocument(
        moment(params.from).format("YYYY-MM-DD"),
        moment(params.to).format("YYYY-MM-DD"),
        params.tourIds,
        params.deliveryTimings,
        appliedFilter,
        t,
        "past-deliveries-document"
      )
    );
  };

  useEffect(() => {
    if (!loading) {
      fetch();
    }
  }, []);

  return (
    <>
      <Navbar />
      <DarkLayout loading={loading}>
        <Box
          p={2}
          boxShadow={3}
          style={{ backgroundColor: "rgb(31, 31, 31)" }}
          borderRadius={3}
        >
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={locales[i18n.language.toLowerCase()]}
          >
            <Formik
              initialValues={form}
              validate={(values) => {
                const errors = {};

                if (!values.from) {
                  errors.from = "Required";
                }

                if (!values.to) {
                  errors.to = "Required";
                }

                return errors;
              }}
              onSubmit={(values) => {
                fetch({
                  from: moment(values.from).toDate(),
                  to: moment(values.to).toDate(),
                  tours: values.tours,
                  delivery_timings: values.delivery_timings,
                });
              }}
              render={({ values, setFieldValue, handleSubmit, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <DatePicker
                        onChange={(date) => {
                          if (date && date.isValid()) {
                            setFieldValue("from", date);
                          }
                          if (date === null) {
                            setFieldValue("from", null);
                          }
                        }}
                        name="from"
                        value={values.from}
                        errors={errors}
                        label="Date delivered from"
                        required
                        className="datepicker-label-white"
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <DatePicker
                        onChange={(date) => {
                          if (date && date.isValid()) {
                            setFieldValue("to", date);
                          }
                          if (date === null) {
                            setFieldValue("to", null);
                          }
                        }}
                        name="to"
                        value={values.to}
                        errors={errors}
                        label="Date delivered to"
                        required
                        className="datepicker-label-white"
                      />
                    </Grid>
                    {user && user?.permissions?.routes?.past_deliveries_export && (
                      <Grid item xs={12} sm={2}>
                        <Box pt={2.8}>
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() =>
                              downloadPDF({
                                from: moment(values.from).toDate(),
                                to: moment(values.to).toDate(),
                                tourIds: mapNamesToIds(values.tours),
                                deliveryTimings: values.delivery_timings,
                              })
                            }
                            fullWidth
                            className="past-deliveries-button"
                          >
                            {t("Download Delivery History")}
                          </Button>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={5}>
                      <CustomMultiSelect
                        values={tours.map((tour) => tour.name)}
                        errors={errors}
                        onChange={(event) => {
                          const selectedTours = event.target.value;
                          const updatedDeliveryTimings =
                            mapNamesToDeliveryTimings(selectedTours);
                          setFieldValue("tours", selectedTours);
                          setFieldValue(
                            "delivery_timings",
                            updatedDeliveryTimings
                          );
                        }}
                        setValues={values.tours}
                        label="Tours"
                        labelId="tours-label"
                        backgroundColor="#1b1b1b"
                        borderColor="#6F9CEB"
                        required={true}
                        allSelected={true}
                        labelColor="white"
                        className="datepicker-label-white"
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <CustomMultiSelect
                        values={
                          values?.tours?.length
                            ? mapNamesToDeliveryTimings(values.tours)
                            : mapNamesToDeliveryTimings(
                                tours.map((tour) => tour.name)
                              )
                        }
                        errors={errors}
                        onChange={(event) =>
                          setFieldValue("delivery_timings", event.target.value)
                        }
                        setValues={values.delivery_timings}
                        label="Delivery Timings"
                        labelId="delivery-timings-label"
                        backgroundColor="#1b1b1b"
                        borderColor="#6F9CEB"
                        required={true}
                        allSelected={true}
                        labelColor="white"
                        className="datepicker-label-white"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Box pt={2.8}>
                        <Button
                          color="primary"
                          size="small"
                          type="submit"
                          variant="contained"
                          disabled={
                            values.tours.length === 0 ||
                            values.delivery_timings.length === 0
                          }
                          fullWidth
                          className="past-deliveries-button"
                        >
                          {t("Search")}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <MaterialTable
          icons={{
            Filter: () => (
              <i className={clsx(classes._filtericon, "fas fa-filter")}></i>
            ),
          }}
          columns={getColumns(PAST_DELIVERIES_TABLE_COLUMNS(t), t)}
          data={mapTableData(pastdeliveries)}
          localization={getLocalization(t)}
          onFilterChange={getFilteredData}
          options={{
            pageSize: 50,
            pageSizeOptions: [50, 100],
            paging: false,
            sorting: false,
            actionsColumnIndex: -1,
            showTitle: false,
            filtering: true,
            search: false,
            detailPanelColumnAlignment: "right",
            headerStyle: {
              backgroundColor: "#121212",
              color: "#F5F5F5",
              borderBottom: "1px solid #525252",
              font: "normal normal normal 12px/24px Roboto",
              fontWeight: "bold",
            },
            cellStyle: {
              color: "white",
              border: "none",
              font: "normal normal normal 12px/24px Roboto",
              padding: "0 16px",
            },
            rowStyle: (rowData) => {
              if (rowData.tableData.id % 2 === 0) {
                return { backgroundColor: "#1F1F1F" };
              }

              return { backgroundColor: "#525252" };
            },
          }}
          detailPanel={[
            {
              icon: () => <ExpandMoreIcon />,
              openIcon: () => <ExpandLessIcon />,
              render: (rowData) => {
                return (
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor:
                            rowData.tableData.id % 2 !== 0
                              ? "#1F1F1F"
                              : "#525252",
                        }}
                      >
                        <TableCell>{t("Route")}</TableCell>
                        <TableCell>{t("Date")}</TableCell>
                        <TableCell>{t("Time")}</TableCell>
                        <TableCell>{t("Description")}</TableCell>
                        <TableCell>{t("Met customer")}</TableCell>
                        <TableCell>{t("Tour")}</TableCell>
                        <TableCell>{t("Packages")}</TableCell>
                        <TableCell>{t("Delivery note number")}</TableCell>
                        <TableCell>{t("Departure")}</TableCell>
                        <TableCell>{t("Actions")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData.Orders.map((order, i) => (
                        <TableRow
                          key={i}
                          onClick={() =>
                            window.open(
                              `${PATHS.routes.mapRecent}/${order.Route.id}/${rowData.id}`,
                              "_blank"
                            )
                          }
                          className={classes.orderRow}
                        >
                          <TableCell>{order.Route.uuid}</TableCell>
                          <TableCell>
                            {moment(order.delivered_at).format("DD.MM.YYYY")}
                          </TableCell>
                          <TableCell>
                            {moment(order.delivered_at).format("HH:mm")}
                          </TableCell>
                          <TableCell>{order.description}</TableCell>
                          <TableCell>
                            {order.Route?.Stops?.[0]?.meet_customer
                              ? t("Yes")
                              : t("No")}
                          </TableCell>
                          <TableCell>{order.Route?.Tour?.name}</TableCell>
                          <TableCell>{order.packages}</TableCell>
                          <TableCell>{order.number}</TableCell>
                          <TableCell>
                            {order.departure ? t(order.departure) : "-"}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();

                                return window.open(
                                  `${PATHS.routes.mapRecent}/${order.Route.id}/${rowData.id}`,
                                  "_blank"
                                );
                              }}
                              color="primary"
                            >
                              <MapIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                );
              },
            },
          ]}
        />
      </DarkLayout>
    </>
  );
};

export default PastDeliveries;
