import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  CUSTOMERS_TABLE_COLUMNS,
  CUSTOMERS_TABLE_COLUMNS_ADMIN,
} from "constants/ui-constants";
import { getColumns, getActions, getLocalization } from "util/table-utils";
import { mapTableData } from "util/helpers";
import { PATHS } from "util/appConstants";
import {
  selectCustomers,
  selectCustomerStatus,
  deleteCustomer,
  exportCustomers,
  getSupplierCustomers,
  getCustomers,
} from "redux/slices/customerSlice";
import withConfirm from "components/dialogs/delete";
import Navbar from "components/Navbar";
import CustomersNavbar from "components/Masterbar/CustomersBar";
import DarkLayout from "components/Shared/DarkLayout";
import { selectUser } from "redux/slices/userSlice";

const useStyles = makeStyles({
  _filtericon: {
    color: "#525252",
    fontSize: "12px",
  },
});
const tableTitle = "CUSTOMERS";

const CustomersList = ({ confirm }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const loading = useSelector(selectCustomerStatus);
  const customers = useSelector(selectCustomers);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user?.permissions?.customers?.admin) {
      dispatch(getCustomers());
    } else {
      dispatch(getSupplierCustomers());
    }
  }, [user?.permissions?.customers?.admin]);

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();
    confirm(() => dispatch(deleteCustomer(rowData.id)), {
      description: "Are you sure?",
    });
  };

  // Add filters based export
  let filters;
  const formatKey = (key) => {
    return key
      .split(".")
      .map((part, index) => {
        if (index > 0) {
          return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        }
        return part.toLowerCase();
      })
      .join("");
  };
  const getFilteredData = (filterState) => {
    filters = filterState.reduce((acc, filter) => {
      const formattedKey = formatKey(filter.column.field);
      acc[formattedKey] = filter.value;
      return acc;
    }, {});
  };
  const exportFile = () => {
    const language = i18n.language === "de" ? "de" : "en";
    dispatch(exportCustomers(language, filters));
  };

  const actions = getActions(
    tableTitle,
    callbackOnDelete,
    () => history.push(PATHS.customers.add),
    null,
    null,
    null,
    t,
    user,
    exportFile
  );
  const customerTableColumns = () => {
    if (user?.permissions?.customers?.admin) {
      return CUSTOMERS_TABLE_COLUMNS_ADMIN(t);
    }
    return CUSTOMERS_TABLE_COLUMNS(t);
  };

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <DarkLayout doublebar loading={loading}>
        <div className="custom-table-styles">
          <MaterialTable
            icons={{
              Filter: () => (
                <i className={clsx(classes._filtericon, "fas fa-filter")}></i>
              ),
            }}
            style={{ display: "flex", flexDirection: "column" }}
            data={mapTableData(customers)}
            title={t(tableTitle)}
            columns={getColumns(customerTableColumns(), t)}
            onRowClick={(e, rowData) =>
              history.push(PATHS.customers.detail.replace(":id", rowData.id))
            }
            actions={actions}
            localization={getLocalization(t)}
            onFilterChange={getFilteredData}
            options={{
              pageSize: 50,
              pageSizeOptions: [50, 100],
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              filtering: true,
              sorting: true,
              headerStyle: {
                backgroundColor: "#121212",
                color: "white !important",
                borderBottom: "1px solid #525252",
                font: "normal normal normal 12px/24px Roboto",
                fontWeight: "bold",
              },
              cellStyle: {
                backgroundColor: "#121212",
                color: "white",
                border: "none",
                font: "normal normal normal 12px/24px Roboto",
                padding: "0 16px",
              },
              searchFieldStyle: {
                color: "#F5F5F5",
              },
              filterCellStyle: {
                color: "#F5F5F5",
              },
              rowStyle: { height: "38px" },
            }}
          />
        </div>
      </DarkLayout>
    </>
  );
};

export default withConfirm(CustomersList);
