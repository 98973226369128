import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const baseUrl = '/tours';

const initialState = {
  tours: [],
  tour: null,
  loading: false,
  timestamp: null,
};

const tourSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setTour: (state, action) => {
      state.tour = action.payload;
    },
    setTours: (state, action) => {
      state.tours = action.payload;
      state.timestamp = +new Date();
    },
    setTourLoading: (state) => {
      state.loading = true;
    },
    setTourReady: (state) => {
      state.loading = false;
    },
    clearTours: (state) => {
      state.tours = [];
      state.tour = null;
      state.timestamp = null;
    },
  },
});

export const {
  setTour,
  setTours,
  setTourLoading,
  setTourReady,
  clearTours,
} = tourSlice.actions;
export default tourSlice.reducer;

export const getTour = (id) => async (dispatch) => {
  const url = baseUrl + `/${id}`;
  dispatch(setTourLoading());

  try {
    const res = await coreApi.fetch(url);
    dispatch(setTour(res));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setTourReady());
  }
};

export const getTours = () => async (dispatch) => {
  dispatch(setTourLoading());

  try {
    const tours = await coreApi.fetch(baseUrl);
    dispatch(setTours(tours));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setTourReady());
  }
};

export const addTour = (payload) => async (dispatch) => {
  dispatch(setTourLoading());

  try {
    const tour = await coreApi.post(baseUrl, payload);

    dispatch(clearTours());

    return tour;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setTourReady());
  }
};

export const editTour = (id, payload) => async (dispatch) => {
  const url = baseUrl + `/${id}`;
  dispatch(setTourLoading());

  try {
    const res = await coreApi.put(url, payload);

    dispatch(clearTours());
    dispatch(
      setShowMessage({
        description: 'Tour modified successfully',
        type: 'success',
      })
    );

    return res;
  } catch (err) {
    dispatch(
      setShowMessage({
        description:
          err.message ?? 'Failed editing tour. Please try again later',
        type: 'error',
      })
    );
  } finally {
    dispatch(setTourReady());
  }
};

export const deleteTour = (id) => async (dispatch) => {
  const url = baseUrl + `/${id}`;
  dispatch(setTourLoading());

  try {
    await coreApi.delete(url);

    dispatch(clearTours());
    dispatch(
      setShowMessage({
        description: 'Tour deleted successfully',
        type: 'success',
      })
    );
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setTourReady());
  }
};

export const getStopsHistory = (from, to, language) => () => {
  const url = `${process.env.REACT_APP_API}${baseUrl.replace(/\/\b/g, '')}/export/stops-history/${from}/${to}/${language}?taira=${localStorage.getItem('token')}`;
	// Since using coreApi.fetch directly doesn't deal with response so we have to download file through anchor tag
  try {
    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  } catch (err) {
    console.error(err);
  } finally {
    // 
  }
};

export const getPastOrders = (from, to, language, t) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}${baseUrl.replace(/\/\b/g, '')}/export/excel-past-orders/${from}/${to}/${language}?taira=${localStorage.getItem('token')}`;
  dispatch(setTourLoading());
	// Since using coreApi.fetch directly doesn't deal with response so we have to download file through anchor tag
  try {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);

    dispatch(
      setShowMessage({
        description: t('File is downloading'),
        type: 'success',
      })
    );
  } catch (err) {
    console.error(err);
    dispatch(
      setShowMessage({
        description: t('Download failed'),
        type: 'error',
      })
    );
  } finally {
    dispatch(setTourReady());
  }
};

const tourSelector = ({ tours }) => tours.tour;
const toursSelector = ({ tours }) => tours.tours;
const tourStatusSelector = ({ tours }) => tours.loading;

export const selectTour = createSelector(tourSelector, (tour) => tour);
export const selectTours = createSelector(toursSelector, (tours) => tours);
export const selectTourStatus = createSelector(
  tourStatusSelector,
  (loading) => loading
);
export const selectTourTimestamp = createSelector(({ tours }) => tours.timestamp, t => t);
