import { createSlice, createSelector } from "@reduxjs/toolkit";
import { coreApi } from "api/core";
import { setShowMessage } from "redux/slices/uiSlice";

const baseUrl = "/zipcodes";
const initialState = {
  zipcodes: [],
  timestamp: null,
  zipcode: null,
  loading: false,
};

const zipcodeSlice = createSlice({
  name: "zipcodes",
  initialState,
  reducers: {
    setZipcode: (state, action) => {
      state.zipcode = action.payload;
    },
    setZipcodes: (state, action) => {
      state.zipcodes = action.payload;
      state.timestamp = +new Date();
    },
    setZipcodeLoading: (state) => {
      state.loading = true;
    },
    setZipcodeReady: (state) => {
      state.loading = false;
    },
    clearZipcodes: (state) => {
      state.zipcodes = [];
      state.zipcode = null;
      state.timestamp = null;
    }
  },
});

export const {
  setZipcode,
  setZipcodes,
  setZipcodeLoading,
  setZipcodeReady,
  clearZipcodes,
} = zipcodeSlice.actions;
export default zipcodeSlice.reducer;

export const getZipcode = (id) => async (dispatch) => {
  const url = baseUrl + `/${id}`;
  dispatch(setZipcodeLoading());

  try {
    const res = await coreApi.fetch(url);
    dispatch(setZipcode(res));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setZipcodeReady());
  }
};

export const getZipcodes = () => async (dispatch) => {
  dispatch(setZipcodeLoading());

  try {
    const zipcodes = await coreApi.fetch(baseUrl);

    dispatch(setZipcodes(zipcodes));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setZipcodeReady());
  }
};

export const addZipcode = (payload, t) => async (dispatch) => {
  dispatch(setZipcodeLoading());

  try {
    const zipcode = await coreApi.post(baseUrl, payload);
    dispatch(clearZipcodes());
    dispatch(
      setShowMessage({
        description: t("Zipcode created"),
        type: "success",
      })
    );

    return zipcode;
  } catch (err) {
    if (err?.response?.data?.errors?.zipcode === 'NOT_UNIQUE') {
      dispatch(
          setShowMessage({
            description: t("NOT_UNIQUE"),
            type: "error",
          })
      );
    } else {
      dispatch(
          setShowMessage({
            description: "There was an error creating the zipcode",
            type: "error",
          })
      );
    }

    throw err;
  } finally {
    dispatch(setZipcodeReady());
  }
};

export const editZipcode = (id, payload) => async (dispatch) => {
  const url = baseUrl + `/${id}`;
  dispatch(setZipcodeLoading());

  try {
    const res = await coreApi.put(url, payload);

    dispatch(clearZipcodes());
    dispatch(
      setShowMessage({
        description: "Zipcode modified successfully",
        type: "success",
      })
    );

    return res;
  } catch (err) {
    dispatch(
      setShowMessage({
        description: "Failed editing zipcode.",
        type: "error",
      })
    );
    throw err;
  } finally {
    dispatch(setZipcodeReady());
  }
};

export const deleteZipcode = (id) => async (dispatch) => {
  const url = baseUrl + `/${id}`;
  dispatch(setZipcodeLoading());

  try {
    const res = await coreApi.delete(url);

    dispatch(
      setShowMessage({
        description: "Zipcode deleted successfully",
        type: "success",
      })
    );
    dispatch(clearZipcodes());

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setZipcodeReady());
  }
};

const zipcodeSelector = ({ zipcodes }) => zipcodes.zipcode;
const zipcodesSelector = ({ zipcodes }) => zipcodes.zipcodes;
const zipcodeStatusSelector = ({ zipcodes }) => zipcodes.loading;

export const selectZipcode = createSelector(zipcodeSelector, (zipcode) => zipcode);
export const selectZipcodes = createSelector(zipcodesSelector, (zipcodes) => zipcodes);
export const selectZipcodeStatus = createSelector(
  zipcodeStatusSelector,
  (loading) => loading
);
export const selectZipcodesTimestamp = createSelector(({ zipcodes }) => zipcodes.timestamp, o => o);
