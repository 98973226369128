import { createSlice, createSelector } from "@reduxjs/toolkit";
import { coreApi } from "api/core";
import { setShowMessage } from "redux/slices/uiSlice";

const baseUrl = "/orders";
const initialState = {
  pastDeliveries: [],
  timestamp: null,
  loading: false,
};

const pastDeliveriesSlice = createSlice({
  name: "pastDeliveries",
  initialState,
  reducers: {
    setpastDeliveries: (state, action) => {
      state.pastDeliveries = action.payload;
      state.timestamp = +new Date();
    },
    setpastDeliveriesLoading: (state) => {
      state.loading = true;
    },
    setsetpastDeliveriesReady: (state) => {
      state.loading = false;
    },
  },
});

export const {
  setpastDeliveries,
  setpastDeliveriesLoading,
  setsetpastDeliveriesReady,
} = pastDeliveriesSlice.actions;

export default pastDeliveriesSlice.reducer;

export const getpastDeliveries =
  ({ from, to, tourIds, deliveryTimings }) =>
  async (dispatch) => {
    dispatch(setpastDeliveriesLoading());
    try {
      const data = await coreApi.fetch(
        `${baseUrl}/delivered/${from}/${to}/${JSON.stringify(
          tourIds
        )}/${JSON.stringify(deliveryTimings)}`
      );

      dispatch(setpastDeliveries(data));

      return data;
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setsetpastDeliveriesReady());
    }
  };

export const getDeliveryHistory =
  ({ from, to, tourIds, deliveryTimings }) =>
  async (dispatch) => {
    dispatch(setpastDeliveriesLoading());
    try {
      console.log("Tours:", tourIds, deliveryTimings);

      const data = await coreApi.fetch(
        `${baseUrl}/delivery-history/${from}/${to}/${JSON.stringify(
          tourIds
        )}/${JSON.stringify(deliveryTimings)}`
      );

      dispatch(setpastDeliveries(data));

      return data;
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setsetpastDeliveriesReady());
    }
  };

export const pastDeliveriesDocument =
  (from, to, tourIds, deliveryTimings, filters, t, path) => (dispatch) => {
    const url = `${process.env.REACT_APP_API}${baseUrl.replace(
      /\/\b/g,
      ""
    )}/${path}/${from}/${to}/${JSON.stringify(tourIds)}/${JSON.stringify(
      deliveryTimings
    )}/${JSON.stringify(filters ?? {})}?taira=${localStorage.getItem("token")}`;
    dispatch(setpastDeliveriesLoading());
    // Since using coreApi.fetch directly doesn't deal with response so we have to download file through anchor tag
    try {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);

      dispatch(
        setShowMessage({
          description: t("File is downloading"),
          type: "success",
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        setShowMessage({
          description: t("Download failed"),
          type: "error",
        })
      );
    } finally {
      dispatch(setsetpastDeliveriesReady());
    }
  };

// const  pastDeliverySelector = ({  pastDeliveries }) =>  pastDeliveries.pastDelivery;
const pastDeliveriesSelector = ({ pastDeliveries }) =>
  pastDeliveries.pastDeliveries;
const pastDeliveryStatusSelector = ({ pastDeliveries }) =>
  pastDeliveries.loading;

// export const selectTour = createSelector(tourSelector, (tour) => tour);
export const selectpastDeliveries = createSelector(
  pastDeliveriesSelector,
  (p) => p
);
export const selectpastDeliveriesStatus = createSelector(
  pastDeliveryStatusSelector,
  (loading) => loading
);
