import { Button } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
export const getColumns = (columns, t) => {
  let updatedColumns = [];
  for (let column of columns) {
    updatedColumns.push({
      ...column,
      title: t(column.title),
      field: column.field,
      render: column.render,
    });
  }

  return updatedColumns;
};

export const getOrdersActions = ({
  t,
  user,
  startTourCheck,
  startTour,
  addHandler,
  createHandover,
}) => {
  const actions = [];

  if (user?.permissions?.routesCreateForDriver || user?.permissions?.routes?.create_driver) {
    actions.push({
      icon: () => {
        return (
          <>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                color: startTourCheck() ? "#6F9CEB" : "#ADADAD",
              }}
            >
              {t("Start Route/s")}
            </span>{" "}
            <PlayCircleOutlineIcon
              style={{
                marginLeft: "10px",
                color: startTourCheck() ? "#6F9CEB" : "#ADADAD",
                height: "20px",
                width: "20px",
                marginRight: "15px",
              }}
            />
          </>
        );
      },
      tooltip: "Start Tours",
      iconProps: { style: { color: "#ADADAD", background: "#1F1F1F" } },
      isFreeAction: true,
      onClick: () => startTour(),
      position: "row",
    });
  }

  if (user?.permissions?.routesCreateDeliveryOrder || user?.permissions?.routes?.create_delivery) {
    actions.push({
      icon: () => {
        return (
          <>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                color: startTourCheck() ? "#6F9CEB" : "#ADADAD",
              }}
            >
              {t("Approve")}
            </span>{" "}
            <PlayCircleOutlineIcon
              style={{
                marginLeft: "10px",
                color: startTourCheck() ? "#6F9CEB" : "#ADADAD",
                height: "20px",
                width: "20px",
                marginRight: "15px",
              }}
            />
          </>
        );
      },
      iconProps: { style: { color: "#ADADAD", background: "#1F1F1F" } },
      isFreeAction: true,
      onClick: () => startTour("DELIVERY"),
      position: "row",
    });
  }

  if (user?.permissions?.handovers?.create) {
    actions.push({
      icon: () => {
        return (
          <>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                color: startTourCheck() ? "#6F9CEB" : "#ADADAD",
              }}
            >
              {t("Create handover protocol")}
            </span>{" "}
            <PlayCircleOutlineIcon
              style={{
                marginLeft: "10px",
                color: startTourCheck() ? "#6F9CEB" : "#ADADAD",
                height: "20px",
                width: "20px",
                marginRight: "15px",
              }}
            />
          </>
        );
      },
      // tooltip: t("Create handover protocol"),
      iconProps: { style: { color: "#ADADAD", background: "#1F1F1F" } },
      isFreeAction: true,
      onClick: () => createHandover(),
      position: "row",
    });
  }

  if (user?.permissions?.ordersCreate || user?.permissions?.orders?.create) {
    actions.push({
      icon: "add",
      // tooltip: t("Add"),
      iconProps: {
        style: { color: "#1F1F1F", background: "#6F9CEB", marginRight: "15px" },
      },
      isFreeAction: true,
      onClick: addHandler,
      position: "row",
    });
  }

  return actions;
};

export const getActions = (
  tableTitle,
  callbackOnDelete,
  addHandler,
  editHandler,
  startTourCheck,
  startTour,
  t,
  user = {},
  exportAction = null
) => {
  let actions = [];
  // @NOTE: Need to show excel file export to admins only, since customers.destroy is only permission
  // that differentiates a customer from user so we are using destroy as check to display to admins only
  if (tableTitle === "CUSTOMERS" && (user?.permissions?.customers?.destroy)) {
    actions.push(
      {
        icon: () => (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={exportAction}
            style={{ marginLeft: '10px' }}
          >
            {t('Export Customers')}
          </Button>
        ),
        isFreeAction: true,
        onClick: () => {},
        position: "toolbar"
      }
  );
  }

  if (tableTitle === "CUSTOMERS" && (user?.permissions?.customersCreate || user?.permissions?.customers?.create)) {
    actions.push(
      {
      icon: "add",
      tooltip: t("Add"),
      iconProps: {
        style: { color: "#1F1F1F", background: "#6F9CEB", marginRight: "15px" },
      },
      isFreeAction: true,
      onClick: addHandler,
      position: "row",
    }
  );
  }

  if (tableTitle === "TOURS" && (user?.permissions?.toursCreate || user?.permissions?.tours?.create)) {
    actions.push({
      icon: "add",
      tooltip: t("Add"),
      iconProps: {
        style: { color: "#1F1F1F", background: "#6F9CEB", marginRight: "15px" },
      },
      isFreeAction: true,
      onClick: addHandler,
      position: "row",
    });
  }

  return actions;
};

export const getLocalization = (t) => {
  return {
    pagination: {
      labelRowsSelect: t("rows"),
      labelDisplayedRows: `{from}-{to} ${t("of")} {count}`,
      nextTooltip: t("Next page"),
      lastTooltip: t("Last page"),
      previousTooltip: t("Previous page"),
      firstTooltip: t("First page"),
    },
    toolbar: {
      nRowsSelected: `{0} ${t("row(s)")} ${t("selected")}`,
      searchTooltip: t("Search"),
      searchPlaceholder: t("Search"),
    },
    header: {
      actions: t("Actions"),
    },
    body: {
      emptyDataSourceMessage: t("No records to display"),
      filterRow: {
        filterTooltip: t("Filter"),
      },
    },
  };
};
