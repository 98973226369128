import React, { useEffect, useRef } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import * as pick from "lodash/pick";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import {
  Checkbox,
  CustomMultiSelect,
  Input,
} from "../Shared/mui-formik-inputs";
import { TourSchema } from "constants/validation-schemas";
import { TourFormAllowedFields } from "constants/forms-submit-allowed-fields";
import { PATHS } from "util/appConstants";
import FormCancelSaveButton from "components/Shared/FormCancelSaveButtons";
import { selectUser } from "redux/slices/userSlice";

const useStyles = makeStyles({
  _editbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  _heading: {
    font: "normal normal normal 28px/40px Questrial",
    color: "#121212",
  },
  _icons: {
    color: "#ADADAD",
    fontSize: "35px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    margin: "0 16px 0 0px",
  },
  _dflex: {
    display: "flex",
    alignItems: "center",
  },
  _save: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#6F9CEB",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _close: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#525252",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _subheading: {
    font: "normal normal 500 22px/32px Roboto",
    color: " #121212",
    marginTop: "44px",
  },
  _edittext: {
    // width: '24px',
    height: "16px",
    font: "normal normal normal 14px / 20px Roboto",
    padding: "4px 8px",
  },
  _cancel: {
    color: "#525252",
    font: " normal normal normal 14px/20px Roboto",
    display: "none",
    position: "absolute",
    marginLeft: "-55px",
    transition: "all 0.3s ease-in-out",
  },
  _savetext: {
    color: "#6F9CEB",
    font: " normal normal normal 14px/20px Roboto",
    display: "none",
    position: "absolute",
    marginLeft: "35px",
    transition: "all 0.3s ease-in-out",
  },
});

const TourForm = ({ initialValues, onSubmit, action }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector(selectUser);
  const { id } = useParams();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: TourSchema,
    initialValues: {
      name: "",
      description: "",
      transport_agent_id: 1,
      active: true,
      is_default: false,
      third_party: false,
      delivery_timings: [],
      ...initialValues,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmit(pick(values, TourFormAllowedFields));
      } catch (err) {
        setSubmitting(false);
      }
    },
  });
  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    submitCount,
    isValid,
    isSubmitting,
    setFieldValue,
  } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  const handleDeliveryTimingsChange = (event) => {
    const value = event.target.value;
    setFieldValue("delivery_timings", value);
  };

  const closeTourHandler = () => {
    action === "ADD"
      ? history.push(PATHS.tours.root)
      : history.push(PATHS.tours.detail.replace(":id", id));
  };

  const cancelButtonRef = useRef(null);
  const saveButtonRef = useRef(null);

  const lastElementRef = useRef(null);
  const deliveryTimings = [
    "MORNING",
    "BEFORE NOON",
    "MIDDAY",
    "AFTERNOON",
    "EVENING",
    "NIGHT",
  ];

  useEffect(() => {
    const handleTabPress = (e) => {
      if (e.key === "Tab") {
        if (
          lastElementRef.current &&
          lastElementRef.current.contains(document.activeElement)
        ) {
          e.preventDefault();
          if (cancelButtonRef.current) {
            cancelButtonRef.current.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleTabPress);
    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, []);

  return (
    <Box>
      <Box display="flex" mb={4}>
        <Box flex={2}>
          <Typography className={classes._heading} variant="h4">
            {action === "ADD" ? t("New Tour") : t("Edit Tour")}
          </Typography>
        </Box>
        <Box flex={2} textAlign="right" position="relative">
          <Box position="absolute" right={0}>
            <FormCancelSaveButton
              disabled={!isValid || isSubmitting}
              onCancel={closeTourHandler}
              onSave={handleSubmit}
              ref={{ cancelRef: cancelButtonRef, saveRef: saveButtonRef }}
            />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label={t("Tour Name")}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label={t("Remark")}
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            errors={errors}
          />
        </Grid>
        {user?.permissions?.tours?.deactivate && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Checkbox
                checked={values.active}
                value={values.active}
                name="active"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                style={{ color: "#6F9CEB" }}
              />
              <Typography component="span" className="font-size-12">
                {t("Active")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Checkbox
                checked={values.is_default}
                value={values.is_default}
                name="is_default"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                style={{ color: "#6F9CEB" }}
              />
              <Typography component="span" className="font-size-12">
                {t("Default tour")}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Checkbox
            checked={values.third_party}
            value={values.third_party}
            name="third_party"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            style={{ color: "#6F9CEB" }}
          />
          <Typography component="span" className="font-size-12">
            {t("Third party")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div ref={lastElementRef}>
            <CustomMultiSelect
              values={deliveryTimings}
              errors={errors}
              onChange={handleDeliveryTimingsChange}
              setValues={values.delivery_timings}
              label={"Delivery Timings"}
              labelId={"delivery-timings-label"}
              backgroundColor={"#dfdfdf"}
              borderColor={"#6F9CEB"}
              required={true}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
TourForm.propTypes = {
  initialValues: PropTypes.shape({}),
  handleAddTour: PropTypes.func,
  // handleEditCompany: PropTypes.func,
};
export default TourForm;
