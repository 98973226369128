import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DocumentIcon from "@material-ui/icons/Description";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import EmptyCircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import FilledCircleIcon from "@material-ui/icons/RadioButtonChecked";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import moment from 'moment';
import { TOURS_TABLE_COLUMNS } from 'constants/ui-constants';
import { getColumns, getActions, getLocalization } from "util/table-utils";
import { mapTableData } from 'util/helpers';
import { PATHS } from 'util/appConstants';
import {
  getZipcodes,
  deleteZipcode,
  selectZipcodeStatus,
  selectZipcodes,
  selectZipcodesTimestamp,
} from 'redux/slices/zipcodeSlice';
import withConfirm from 'components/dialogs/delete';
import Navbar from 'components/Navbar';
import CustomersNavbar from 'components/Masterbar/CustomersBar';
import DarkLayout from 'components/Shared/DarkLayout';
import { selectUser } from 'redux/slices/userSlice';

const useStyles = makeStyles({
  _filtericon: {
    color: '#525252',
    fontSize: '12px',
  },
});

const tableTitle = 'ZIPCODES';

const ZipcodesList = ({ confirm }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectZipcodeStatus);
  const zipcodes = useSelector(selectZipcodes);
  const timestamp = useSelector(selectZipcodesTimestamp);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!loading && !timestamp) {
      dispatch(getZipcodes());
    }
  }, [dispatch, zipcodes]);

  useEffect(() => {
    dispatch(getZipcodes());
  }, []);

  const callbackOnDelete = (rowData) => {
    confirm(() => {
      dispatch(deleteZipcode(rowData.id)).then(() => dispatch(getZipcodes()))
    }, {
      description: t('Delete zipcode?'),
    });
  };

  const actions = [];

  if (user?.permissions?.zipcodes?.create) {
    actions.push({
      icon: "add",
      tooltip: t("Add"),
      iconProps: {
        style: { color: "#1F1F1F", background: "#6F9CEB", marginRight: "15px" },
      },
      isFreeAction: true,
      onClick: () => history.push(PATHS.zipcodes.add),
      position: "row",
    });
  }

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <DarkLayout doublebar loading={loading}>
        <div className="custom-table-styles">
          <MaterialTable
            icons={{
              Filter: () => (
                <i className={clsx(classes._filtericon, "fas fa-filter")}></i>
              ),
            }}
            style={{ display: "flex", flexDirection: "column" }}
            data={mapTableData(zipcodes)}
            title={t(tableTitle)}
            columns={[
              {
                title: t("Zipcode"),
                field: 'zipcode',
              },
              {
                title: t('Tour'),
                field: 'Tour.name',
              },
              {
                title: t('Actions'),
                render: (rowData) => {
                  if (!user?.permissions?.zipcodes?.destroy) {
                    return null;
                  }

                  return (
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        callbackOnDelete(rowData)
                      }}
                    >
                      <DeleteSharpIcon />
                    </IconButton>
                  )
                }
              },
            ]}
            actions={actions}
            localization={getLocalization(t)}
            onRowClick={(e, rowData) =>
              history.push(PATHS.zipcodes.edit.replace(":id", rowData.id))
            }
            options={{
              pageSize: 50,
              pageSizeOptions: [50, 100],
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              filtering: true,
              headerStyle: {
                backgroundColor: "#121212",
                color: "white",
                borderBottom: "1px solid #525252",
                font: "normal normal normal 12px/24px Roboto",
                fontWeight: "bold",
              },
              cellStyle: {
                backgroundColor: "#121212",
                color: "white",
                border: "none",
                font: "normal normal normal 12px/24px Roboto",
                padding: "0 16px",
              },
              searchFieldStyle: {
                color: "#F5F5F5",
              },
              filterCellStyle: {
                color: "#F5F5F5",
              },
              rowStyle: { height: "38px" },
            }}
          />
        </div>
      </DarkLayout>
    </>
  );
};

export default withConfirm(ZipcodesList);
